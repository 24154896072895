const policySections = [
  {
    title: "Privacy Policy",
    content: [
      "This Privacy Policy applies to the SAWA RIDE software and mobile application and is intended to comply with the data protection laws and regulations of the Kingdom of Saudi Arabia. By using the SAWA RIDE application or software, you agree to the terms outlined in this policy, which establish a legally binding agreement between the user and SAWA RIDE. These terms govern the collection, usage, and protection of personal data and apply to all interactions with the SAWA RIDE application or software.",
    ],
  },
  {
    title: "Information Collected and Use of Information",
    content: [
      "We collect certain information, including personal information, from you when you use our application or communicate with us. We've summarized this information in the chart below.",
    ],
    tableData: [
      {
        category: "Profile",
        data: "Name, Contact Number, Email ID, Address, profile picture",
        purpose: [
          "To trigger verification OTP SMS",
          "To create and update accounts",
          "For verifying identity and eligibility",
          "To enable platform features and matching",
          "To deliver receipts",
          "To facilitate driver-passenger communication",
          "To process payments",
          "To provide customer support",
        ],
      },
      {
        category: "Location",
        data: "Precise or approximate location data collected from customers' devices, recent location searches",
        purpose: [
          "To enable Platform features",
          "To determine pricing for a ride",
          "To enable driver matching for a ride",
          "To detect unsafe behavior and prevent fraud",
          "To investigate and address concerns in relation to customer support",
          "For customization",
        ],
      },
      {
        category: "Ride Data",
        data: "History of rides, saved addresses, favourites",
        purpose: [
          "To enable the customers to use the app in a personalised manner",
          "To enable the customers to book rides with ease",
        ],
      },
      {
        category: "Device Data",
        data: "Hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.",
        purpose: [
          "To perform internal operations",
          "To detect unsafe behavior and prevent fraud",
          "To monitor and improve support",
          "To investigate and address concerns in relation to customer support",
          "To develop and improve Platform features",
        ],
      },
      {
        category: "Communications Data",
        data: "Time and date of calls, call recording, texts and in-app messages between users enabled through Platform and the content of text and in-app messages.",
        purpose: [
          "To enable communication between users",
          "To detect unsafe behavior and prevent frauds",
          "To report unsafe incidents",
          "To prevent and resolve conflicts between users",
          "To monitor and improve support",
        ],
      },
      
    ],
  },
  {
    title: "Retention",
    content: [
      "All information, including personal information collected under this Privacy Policy, will be retained by Sawa Ride for as long as permissible under applicable law.",
    ],
  },
  {
    title: "Rights",
    content: [
      "You have the right to access your profile on the Platform at any time to add, remove, or modify your information.",
    ],
  },
  {
    title: "Data Security",
    content: [
      "Sawa Ride takes necessary organizational and technical measures to protect the confidentiality and security of your information.",
    ],
  },
  {
    title: "Disclosures and Transfer",
    content: [
      "We share your information with third parties for various purposes as detailed herein below:",
    ],
    tableData: [
      {
        type: "Drivers and Co-riders",
        description:
          "We share: passenger's first name, rating, and pick-up and/or drop-off locations with drivers; rider's first name with other riders in a car sharing trip. Riders in car sharing trips may also see the drop-off location of the other riders.",
      },
      {
        type: "Other people at request of the passenger/rider",
        description:
          "We share data with: Other people at the user's request. For example, we share a user's ETA and location with a friend when requested by that user. Emergency services: We offer features that enable users to share their data with police in the event of an emergency or after certain incidents.",
      },
      {
        type: "Hosting and IT service providers",
        description:
          "IT vendors including cloud service providers to securely store your information including personal and sensitive information.",
      },
      {
        type: "Payment processing partners and vendors",
        description:
          "Payment processors and banking partners to facilitate payment transactions.",
      },
      {
        type: "Business transaction entities",
        description:
          "In the event that Sawa Ride enters into a transaction that alters the structure of our business, we shall transfer, share, assign data including Personal Information to the relevant entity.",
      },
    ],
  },
  {
    title: "Grievance",
    content: [
      "If you have a grievance or complaint, questions, comments, concerns or feedback, please contact us.",
    ],
  },
];

const PrivacyPolicy = ({ language }) => {
  return (
    <div className="bg-black text-white py-16 md:py-24">
      {policySections.map((section, index) => (
        <section
          key={index}
          className={`container my-auto mx-auto px-4 py-4 mb-10 ${
            section.tableData ? "bg-gray-800 p-6 rounded-lg" : ""
          } ${
            index === 0
              ? "h-96 flex items-start p-20 sm:justify-center flex-col"
              : ""
          }`}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4">
            {section.title}
          </h2>
          {section.content.map((paragraph, i) => (
            <p
              key={i}
              className="text-lg md:text-xl lg:text-2xl text-gray-300 leading-relaxed mb-6"
            >
              {paragraph}
            </p>
          ))}
          {section.tableData && (
            <div className="overflow-x-auto">
              {section.title ===
              "Information Collected and Use of Information" ? (
                <table className="w-full text-gray-300 p-10">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="p-4 text-left">
                        Categories of Information
                      </th>
                      <th className="p-4 text-left">Data</th>
                      <th className="p-4 text-left">Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.tableData.map((row, idx) => (
                      <tr
                        key={idx}
                        className={`bg-gray-800 ${
                          idx % 2 === 0
                            ? "hover:bg-gray-700"
                            : "hover:bg-gray-600"
                        } transition duration-300`}
                      >
                        <td className="p-4">{row.category}</td>
                        <td className="p-4">{row.data}</td>
                        <td className="p-4">
                          <ul className="list-disc list-inside">
                            {row.purpose.map((item, j) => (
                              <li key={j}>{item}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="w-full text-gray-300 p-10">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="p-4 text-left">Type of Third Party</th>
                      <th className="p-4 text-left">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.tableData.map((row, idx) => (
                      <tr
                        key={idx}
                        className={`bg-gray-800 ${
                          idx % 2 === 0
                            ? "hover:bg-gray-700"
                            : "hover:bg-gray-600"
                        } transition duration-300`}
                      >
                        <td className="p-4">{row.type}</td>
                        <td className="p-4">{row.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </section>
      ))}
    </div>
  );
};

export default PrivacyPolicy;


