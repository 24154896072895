import React, { useRef, useEffect, useState, memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import riderLogo from "../../assets/svgs/rider-logo.svg";
import driverLogo from "../../assets/svgs/driver-logo.svg";
import AppIconOverlay from "../IconOverlay";
import { useDeviceDetect } from "../../hooks/useDeviceDetect";

const getStoreLinks = (isIOS) => ({
  driver: isIOS
    ? "https://apps.apple.com/driver"
    : "https://play.google.com/store/driver",
  rider: isIOS
    ? "https://apps.apple.com/rider"
    : "https://play.google.com/store/rider",
});

const translations = {
  en: {
    title: "Download the App",
    riderApp: "Rider App",
    driverApp: "Driver App",
    closeButton: "Close",
    dragToClose: "Drag down to close",
  },
  ar: {
    title: "قم بتنزيل التطبيق",
    riderApp: "تطبيق الراكب",
    driverApp: "تطبيق السائق",
    closeButton: "إغلاق",
    dragToClose: "اسحب لأسفل للإغلاق",
  },
};

const animationVariants = {
  drawer: {
    hidden: { y: "100%", opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 30 },
    },
    exit: {
      y: "100%",
      opacity: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  },
  overlay: {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  },
};

// Memoized AppButton component to prevent unnecessary re-renders
const AppButton = memo(({ type, link, logo, label }) => (
  <motion.div variants={animationVariants.drawer}>
    <AppIconOverlay>
      <a
        href={link}
        className="flex flex-row gap-5 items-center justify-center bg-gray-900 text-white py-4 rounded-lg transition-all duration-300 group hover:scale-105"
        aria-label={label}
      >
        <img src={logo} alt={label} className="w-12 h-auto" />
        <span>{label}</span>
      </a>
    </AppIconOverlay>
  </motion.div>
));

const DownloadDrawer = ({ isOpen, onClose, language = "en" }) => {
  const drawerRef = useRef(null);
  const { isIOS } = useDeviceDetect();
  const storeLinks = getStoreLinks(isIOS);
  const currentTranslations = translations[language] || translations.en;
  const [dragStart, setDragStart] = useState(0);
  const threshold = 50;

  const handleDragStart = (event, info) => {
    setDragStart(info.point.y);
  };

  const handleDragEnd = (event, info) => {
    if (info.point.y - dragStart > threshold) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && isOpen) onClose();
    };
    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/40 z-20"
            variants={animationVariants.overlay}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />

          <motion.div
            ref={drawerRef}
            className="fixed inset-x-0 bottom-0 z-30 bg-black text-white rounded-t-2xl shadow-2xl"
            variants={animationVariants.drawer}
            initial="hidden"
            animate="visible"
            exit="exit"
            drag="y"
            dragConstraints={{ top: 0, bottom: 0 }}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            style={{ touchAction: "none" }}
          >
            {/* <motion.div
              className="w-full flex flex-col items-center pt-2 pb-1"
              variants={animationVariants.drawer}
            >
              <div className="w-12 h-1.5 bg-gray-600 rounded-full" />
              <span className="text-xs text-gray-500 mt-1">
                {currentTranslations.dragToClose}
              </span>
            </motion.div> */}

            <div className="p-6 sm:w-1/2 sm:mx-auto">
              <motion.div
                className="flex justify-between items-center mb-6"
                variants={animationVariants.drawer}
              >
                <h2 className="text-xl font-bold">
                  {currentTranslations.title}
                </h2>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-gray-800 rounded-full transition-all duration-300"
                  aria-label="Close drawer"
                >
                  <X size={24} />
                </button>
              </motion.div>

              <div className="grid grid-cols-2 gap-4">
                <AppButton
                  type="rider"
                  link={storeLinks.rider}
                  logo={riderLogo}
                  label={currentTranslations.riderApp}
                />
                <AppButton
                  type="driver"
                  link={storeLinks.driver}
                  logo={driverLogo}
                  label={currentTranslations.driverApp}
                />
              </div>

              {/* <motion.button
                variants={animationVariants.drawer}
                onClick={onClose}
                className="mt-6 w-full bg-white text-gray-900 py-3 rounded-xl font-medium hover:bg-gray-100 transition-all duration-300 focus:outline-none"
              >
                {currentTranslations.closeButton}
              </motion.button> */}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default DownloadDrawer;
