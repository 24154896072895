// hooks/useLanguage.js
import { useState, useEffect, useCallback } from "react";

function useLanguage() {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const userLanguage =
      localStorage.getItem("language") || navigator.language.slice(0, 2);
    setLanguage(userLanguage === "ar" ? "ar" : "en");
  }, []);

  const toggleLanguage = useCallback(() => {
    const newLanguage = language === "en" ? "ar" : "en";
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  }, [language]);

  return { language, toggleLanguage };
}

export default useLanguage;
