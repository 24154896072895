import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import sawaLogo from "../../assets/images/sawa-logo.png";

const Navbar = ({ onDownloadClick, onLanguageToggle, language }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const toggleMenu = () => setIsOpen(!isOpen);

  // Check if the current route is the homepage
  const isHomePage = location.pathname === "/";

  return (
    <nav
      className={`fixed w-full z-50 ${
        isOpen
          ? "bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg"
          : "sm:bg-black sm:bg-opacity-50 sm:backdrop-filter sm:backdrop-blur-lg"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {/* Show logo on larger screens regardless of the page */}
            <Link
              to="/"
              className="text-white flex items-center gap-5 font-bold text-xl"
            >
              <img
                src={sawaLogo}
                className={`h-12 ${isHomePage ? "hidden sm:block" : "block"}`} // Hide on mobile homepage, show on other pages
                alt="sawa logo"
              />
              <span className="hidden sm:block">
                {language === "en" ? "Sawa Ride" : "سوا رايد"}
              </span>
            </Link>
          </div>
          <div className="hidden md:flex space-x-4">
            {[
              { path: "/", labelEn: "Home", labelAr: "الصفحة الرئيسية" },
              { path: "/plans", labelEn: "Plans", labelAr: "الخطط" },
              { path: "/FAQ", labelEn: "FAQ", labelAr: "الأسئلة الشائعة" },
            ].map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-bold px-3 py-2 rounded-md text-sm font-medium"
                    : "text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                }
              >
                {language === "en" ? item.labelEn : item.labelAr}
              </NavLink>
            ))}
            <button
              onClick={onDownloadClick}
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              {language === "en" ? "Download" : "تحميل"}
            </button>
            <button
              onClick={onLanguageToggle}
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              {language === "en" ? "عربي" : "English"}
            </button>
          </div>
          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-300 focus:outline-none"
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden bg-opacity-50">
          <div className="px-4 pt-2 pb-3 space-y-1">
            {[
              { path: "/", labelEn: "Home", labelAr: "الصفحة الرئيسية" },
              { path: "/plans", labelEn: "Plans", labelAr: "الخطط" },
              { path: "/FAQ", labelEn: "FAQ", labelAr: "الأسئلة الشائعة" },
            ].map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "block text-white font-bold px-3 py-2 rounded-md text-base font-medium"
                    : "block text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
                }
                onClick={toggleMenu}
              >
                {language === "en" ? item.labelEn : item.labelAr}
              </NavLink>
            ))}
            <button
              onClick={() => {
                toggleMenu();
                onDownloadClick();
              }}
              className="block text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              {language === "en" ? "Download" : "تحميل"}
            </button>
            <button
              onClick={() => {
                toggleMenu();
                onLanguageToggle();
              }}
              className="block text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              {language === "en" ? "عربي" : "English"}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
