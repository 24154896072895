import React, { useState, useEffect, useRef, useCallback } from "react";
import { Smartphone, Car, Wallet, Bell, CheckCircle } from "lucide-react";
import walletSvg from "../../assets/svgs/wallet.svg";

const HowItWorks = ({ language }) => {
  const [activeTab, setActiveTab] = useState("rider");
  const riderButtonRef = useRef(null);
  const driverButtonRef = useRef(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});

  // Translate function for text
  const translate = (text, arText) => (language === "ar" ? arText : text);

  // Update the indicator position on tab change
  const updateIndicatorPosition = useCallback(() => {
    const activeButtonRef =
      activeTab === "rider" ? riderButtonRef : driverButtonRef;
    if (activeButtonRef.current) {
      const { offsetWidth, offsetLeft } = activeButtonRef.current;
      setIndicatorStyle({ width: offsetWidth, left: offsetLeft });
    }
  }, [activeTab]);

  useEffect(() => {
    updateIndicatorPosition(); // Initial indicator position
  }, [activeTab, updateIndicatorPosition]);

  const tabs = [
    { label: "Riders", arLabel: "الركاب", ref: riderButtonRef, key: "rider" },
    {
      label: "Drivers",
      arLabel: "السائقون",
      ref: driverButtonRef,
      key: "driver",
    },
  ];

  const contentData = {
    rider: [
      {
        icon: <Smartphone strokeWidth={1} className="w-16 h-16 text-white" />,
        title: translate("Book", "احجز"),
        description: translate("Choose a ride and driver", "اختر رحلة وسائق"),
      },
      {
        icon: <Car strokeWidth={1} className="w-16 h-16 text-white" />,
        title: translate("Ride", "اركب"),
        description: translate(
          "Travel safe to your destination",
          "سافر بأمان إلى وجهتك"
        ),
      },
      {
        icon: <Wallet strokeWidth={1} className="w-16 h-16 text-white" />,
        title: translate("Pay Directly", "ادفع مباشرة"),
        description: translate(
          "100% fare goes to the driver",
          "يذهب 100٪ من الأجرة للسائق"
        ),
      },
    ],
    driver: [
      {
        icon: <Bell strokeWidth={1} className="w-16 h-16 text-white" />,
        title: translate("Get Request", "احصل على طلب"),
        description: translate(
          "Get ride requests from riders",
          "تلقَ طلبات من الركاب"
        ),
      },
      {
        icon: <CheckCircle strokeWidth={1} className="w-16 h-16 text-white" />,
        title: translate("Accept & Ride", "اقبل واركب"),
        description: translate(
          "Choose your ride and accept",
          "اختر رحلتك واقبلها"
        ),
      },
      {
        icon: (
          <img
            src={walletSvg}
            alt="wallet svg"
            className="w-16 h-16 text-white"
          />
        ),
        title: translate("Receive Payment", "تلقي الدفع"),
        description: translate(
          "Get payment directly from the rider",
          "احصل على الدفع مباشرة من الراكب"
        ),
      },
    ],
  };

  return (
    <section className="bg-gray-900 text-white py-12 px-6 md:px-20 lg:px-32">
      <h2 className="text-4xl font-bold text-center mb-8">
        {translate("How It Works", "كيف تعمل")}
      </h2>

      <div className="relative flex justify-center gap-4 mb-10">
        <div
          className="absolute top-0 h-full bg-white rounded-lg transition-left transition-width duration-100 ease-in-out"
          style={{ ...indicatorStyle, height: "100%" }}
        />
        {tabs.map(({ label, arLabel, ref, key }) => (
          <button
            key={key}
            ref={ref}
            className={`relative z-10 px-6 py-2 font-semibold text-xl rounded-lg transition-colors duration-300 ${
              activeTab === key ? "text-gray-900" : "text-gray-300"
            }`}
            onClick={() => {
              if (activeTab !== key) {
                setActiveTab(key);
              }
            }}
          >
            {translate(label, arLabel)}
          </button>
        ))}
      </div>

      <div className="grid gap-8 md:grid-cols-3">
        {contentData[activeTab].map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-6 bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg"
          >
            {item.icon}
            <h3 className="text-2xl font-semibold mt-4 mb-2">{item.title}</h3>
            <p className="text-gray-400">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
