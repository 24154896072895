import React, { useState, useCallback, useMemo } from "react";
import HeroSection from "../../components/hero";
import FeaturesSection from "../../components/feature";
import DownloadAppSection from "../../components/downloadAppSection";
import FAQSection from "../../components/faqSection";
import DownloadDrawer from "../../components/downloadDrawer";
import DriverBenefitsSection from "../../components/driver";
import Testimonials from "../../components/testimonial";
import HowItWorks from "../../components/howItWorksTab";




function Home({ language }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Memoizing handlers to prevent unnecessary re-renders
  const openDrawer = useCallback(() => setIsDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), []);

  // Memoize components to prevent unnecessary renders
  const heroSection = useMemo(
    () => <HeroSection onDownloadClick={openDrawer} language={language} />,
    [language, openDrawer]
  );

  return (
    <>
      {heroSection}
      <FeaturesSection language={language}/>

      {/* Use Suspense for lazy-loaded components */}
    
        <DriverBenefitsSection language={language} />
        <Testimonials language={language} />
        <HowItWorks language={language} />
    

      <DownloadAppSection onDownloadClick={openDrawer} language={language} />
      <FAQSection language={language} />

      {/* Conditionally render DownloadDrawer only when open */}
      {isDrawerOpen && (
        <DownloadDrawer
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          language={language}
        />
      )}
    </>
  );
}

export default React.memo(Home);
