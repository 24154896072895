import React from "react";

const content = {
  en: "Coming Soon",
  ar: "قريباً",
};

const AppIconOverlay = ({ children, language = "en" }) => {
  const message = content[language] || content.en;

  return (
    <div className="relative">
      {children}
      <div className="absolute inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#A5FF7E] to-[#00A8C6] font-bold text-2xl">
          {message}
        </span>
      </div>
    </div>
  );
};

export default AppIconOverlay;
