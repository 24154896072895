import React from "react";
import { Banknote, CirclePercent, ArrowRightLeft } from "lucide-react";

const content = {
  en: {
    title: "Why Choose Us?",
    features: [
      {
        title: "Zero Commission",
        description:
          "Drivers keep all their earnings, maximizing their income.",
        icon: CirclePercent,
      },
      {
        title: "Affordable Rides",
        description: "Transparent and budget-friendly fares for passengers.",
        icon: Banknote,
      },
      {
        title: "Direct P2P Payments",
        description:
          "Riders pay drivers instantly via wallets like STCPay, UrPay, Barq, MobilyPay, or cash.",
        icon: ArrowRightLeft,
      },
    ],
  },
  ar: {
    title: "لماذا تختارنا؟",
    features: [
      {
        title: "عمولة صفرية",
        description: "يحافظ السائقون على جميع أرباحهم، مما يزيد دخلهم",
        icon: CirclePercent,
      },
      {
        title: "ركوب ميسور التكلفة",
        description: "رسوم مناسبة لميزانية الركاب ",
        icon: Banknote,
      },
      {
        title: "مدفوعات مباشرة بين الأفراد",
        description:
          "  يدفع الركاب للسائقين على الفور عبر محافظ مثل STCPay, ,UrPay ,Barq , MobilyPay أو نقدًا",
        icon: ArrowRightLeft,
      },
    ],
  },
};

const FeaturesSection = ({ language }) => {
  const { title, features } = content[language] || content.en; // default to English if language is not supported

  return (
    <section className="bg-black text-white py-16 md:py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center">
          {title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => {
            const IconComponent = feature.icon;
            return (
              <div
                key={index}
                className="feature-card bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
              >
                <div className="flex justify-center mb-4">
                  <IconComponent className="w-12 h-12 text-white stroke-2" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center">
                  {feature.title}
                </h3>
                <p className="text-gray-300 text-center">
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
