import React, { useState, } from "react";
import { Headset, HandCoins } from "lucide-react";
import { Link } from "react-router-dom";

const DriverBenefitsSection = ({ language = "en" }) => {
  const [driverAppLink, setDriverAppLink] = useState("");

  // useEffect(() => {
  //   const isIOS =
  //     /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //   setDriverAppLink(
  //     isIOS
  //       ? "https://apps.apple.com/" // iOS link for driver app
  //       : "https://play.google.com/store/" // Android link for driver app
  //   );
  // }, []);

  // Define benefits content with English and Arabic translations
  const benefitsContent = {
    en: [
      {
        title: "100% Earnings",
        description:
          "Keep every cent you earn. No commission, no hidden fees. Your work, your money.",
        icon: HandCoins,
      },
      {
        title: "Flexible Hours",
        description:
          "Work when you want, how you want. Set your own schedule and be your own boss.",
        icon: () => (
          <svg
            className="w-8 h-8 mr-3 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        ),
      },
      {
        title: "Safety First",
        description:
          "Our advanced security features ensure a safe environment for every ride you take.",
        icon: () => (
          <svg
            className="w-8 h-8 mr-3 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            ></path>
          </svg>
        ),
      },
      {
        title: "In-App Support",
        description:
          "Access 24/7 assistance through the app, ensuring you have help whenever you need it for any ride-related issues.",
        icon: Headset,
      },
    ],
    ar: [
      {
        title: "100٪ من الأرباح",
        description: "احتفظ بكل مبلغ تكسبه, بدون عمولة وبدون رسوم مخفية",
        icon: HandCoins,
      },
      {
        title: "ساعات مرنة",
        description:
          "اعمل عندما تريد وبالطريقة التي تريدها. حدد جدولك الخاص وكن رئيسًا لنفسك",
        icon: () => (
          <svg
            className="w-8 h-8 mr-3 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        ),
      },
      {
        title: "السلامة أولاً",
        description:
          "ميزات الأمان المتقدمة لدينا تضمن بيئة آمنة لكل رحلة تقوم بها",
        icon: () => (
          <svg
            className="w-8 h-8 mr-3 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            ></path>
          </svg>
        ),
      },
      {
        title: "الدعم داخل التطبيق",
        description:
          "الوصول إلى المساعدة على مدار الساعة طوال أيام الأسبوع من خلال التطبيق، مما يضمن حصولك على المساعدة عند الحاجة لأي مشكلة تتعلق بالرحلة",
        icon: Headset,
      },
    ],
  };

  return (
    <section className="bg-gray-900 text-white py-16 md:py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center">
          {language === "en" ? "Driver Benefits" : "فوائد السائقين"}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {benefitsContent[language].map((benefit, index) => {
            const IconComponent = benefit.icon;
            return (
              <div
                key={index}
                className="benefit-card bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
              >
                <div className="flex items-center mb-4">
                  <IconComponent className="w-8 h-8 mr-3 text-white" />
                  <h3 className="text-xl font-semibold">{benefit.title}</h3>
                </div>
                <p className="text-gray-300">{benefit.description}</p>
              </div>
            );
          })}
        </div>

        {/* Call-to-Action Button */}
        <div className="mt-16 text-center">
          <Link
            to={"/"}
            className="inline-block bg-white text-black font-bold py-3 px-8 rounded-full hover:bg-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          >
            {language === "en" ? "Become a Driver" : "كن سائقاً"}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DriverBenefitsSection;
