// hooks/useDeviceDetect.js
import React, { useEffect, useState } from "react";

export const useDeviceDetect = () => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return { isIOS };
};
