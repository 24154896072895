import "./App.css";
import React, { useState, useCallback } from "react";
import ScrollToTop from "./components/scrollToTop";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import DownloadDrawer from "./components/downloadDrawer";
import useLanguage from "./hooks/useLanguage"; // custom hook for language management
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./pages/PageNotFound";
import ComingSoonPage from "./pages/ComingSoon";

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { language, toggleLanguage } = useLanguage();

  const openDrawer = useCallback(() => setIsDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), []);

  return (
    <>
      <Navbar
        onDownloadClick={openDrawer}
        onLanguageToggle={toggleLanguage}
        language={language}
      />
      <ScrollToTop />

      <Routes>
        <Route index path="/" element={<Home language={language} />} />
        <Route path="/aboutUs" element={<AboutUs language={language} />} />
        <Route path="/plans" element={<ComingSoonPage language={language} />} />
        <Route path="/FAQ" element={<FAQ language={language} />} />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy language={language} />}
        />
        <Route path="/*" element={<PageNotFound language={language} />} />
      </Routes>

      {/* Conditionally render DownloadDrawer */}
      {isDrawerOpen && (
        <DownloadDrawer
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          language={language}
        />
      )}

      <Footer language={language} />
    </>
  );
}

export default React.memo(App);
