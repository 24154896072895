import React, { useMemo, useState, useEffect } from "react";
import SawaLogo from "../../assets/svgs/rider-logo.svg";

const content = {
  en: {
    title: "Book a Ride with ",
    gradient: "Zero Commission",
    subtitle: "An app by drivers, for the people.",
    subtext: "100% direct payment to drivers.",
    buttonText: "Download App",
  },
  ar: {
    title: "احجز رحلة",
    gradient: " بدون عمولة",
    subtitle: "تطبيق من السائقين للناس",
    subtext: "دفع مباشر للسائقين ٪١٠٠",
    buttonText: "تنزيل التطبيق",
  },
};

const HeroSection = React.memo(({ onDownloadClick, language }) => {
  const selectedContent = useMemo(
    () => content[language] || content.en,
    [language]
  );

  return (
    <header className="relative flex items-start sm:items-center justify-center h-screen overflow-hidden">
      {/* Background Spline Viewer with Conditional Loading Background */}
      <iframe
        src="https://my.spline.design/3dcitynavigationcopy-78a5326874435a5b665b6111aa66396e/"
        frameBorder="0"
        className="absolute inset-0 w-full h-full z-0" // Cover the entire section
        title="3D City"
      ></iframe>

      {/* Background Overlay */}
      <div className="absolute inset-0 w-full h-full bg-gray-900 opacity-30 z-10"></div>

      {/* Content Overlay */}
      <div className="relative z-30 p-5 text-2xl pt-20 px-10 text-white flex flex-col items-start">
        <div>
          <img
            src={SawaLogo}
            alt="sawa logo"
            className="w-24 md:w-32 mb-24 lg:w-32 md:mx-0 black sm:hidden"
            loading="lazy"
          />
        </div>

        <div>
          <h1 className="text-5xl md:text-7xl font-bold mb-4 animate-fade-in">
            {selectedContent.title}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#A5FF7E] to-[#00A8C6]">
              {selectedContent.gradient}
            </span>
          </h1>
          <p className="text-xl md:text-2xl mb-8 animate-fade-in">
            {selectedContent.subtitle}
            <br />
            <span className="text-gray-400">{selectedContent.subtext}</span>
          </p>
          <button
            onClick={onDownloadClick}
            className="inline-block bg-white text-black font-bold py-3 px-8 rounded-full hover:bg-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          >
            {selectedContent.buttonText}
          </button>
        </div>
      </div>
    </header>
  );
});

export default HeroSection;
