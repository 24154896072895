import React from "react";

const PageNotFound = ({ language }) => {
  return (
    <div className="min-h-screen bg-white font-serif py-10">
      <div className="container mx-auto px-4">
        <div className="flex justify-center">
          <div className="w-full max-w-2xl text-center">
            <div className="bg-[url('https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif')] h-96 bg-center bg-no-repeat mb-4">
              <h1 className="text-8xl pt-16">404</h1>
            </div>
            <div className="-mt-12">
              <h3 className="text-2xl font-bold mb-4">Look like you're lost</h3>
              <p className="mb-6">
                The page you are looking for is not available!
              </p>
              <a
                href="/"
                className="inline-block px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200"
              >
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
