import React from "react";
import TeamSection from "../../components/teamSection";

const aboutUsData = {
  en: {
    values: [
      {
        title: "Transparency",
        description:
          "We operate on a zero-commission model, ensuring that drivers keep 100% of their earnings. No hidden fees, no surprises—just fair pay for hard work.",
      },
      {
        title: "Empowerment",
        description:
          "Our drivers are at the heart of our service. We are dedicated to supporting them with a direct payment system, allowing riders to pay through P2P wallets like STCPay, UrPay, Barq, and MobilyPay, or even cash.",
      },
      {
        title: "Community",
        description:
          "We believe in building a community that connects riders and drivers. By choosing SAWA RIDE, you’re not just getting a ride; you’re joining a movement focused on supporting local livelihoods.",
      },
    ],
    reasonsToChoose: [
      {
        title: "Zero Commission",
        description:
          "Unlike other ride-hailing platforms, we do not take a cut from driver earnings, allowing them to maximize their income.",
      },
      {
        title: "Direct Payments",
        description:
          "Our P2P payment system simplifies the transaction process, making it easy for riders to pay directly to drivers.",
      },
      {
        title: "No Surge Pricing",
        description:
          "We believe in fair pricing, which is why we do not engage in surge pricing. What you see is what you pay, every time.",
      },
    ],
  },
  ar: {
    values: [
      {
        title: "الشفافية",
        description:
          "نحن نعمل بنموذج عدم العمولة، مما يضمن للسائقين الاحتفاظ بنسبة 100٪ من أرباحهم. لا رسوم خفية، لا مفاجآت—فقط أجر عادل للعمل الشاق.",
      },
      {
        title: "التمكين",
        description:
          "سائقونا في قلب خدماتنا. نحن ملتزمون بدعمهم من خلال نظام الدفع المباشر، مما يسمح للركاب بالدفع عبر المحافظ الإلكترونية مثل STCPay و UrPay و Barq و MobilyPay، أو حتى نقدًا.",
      },
      {
        title: "المجتمع",
        description:
          "نؤمن ببناء مجتمع يربط بين الركاب والسائقين. من خلال اختيار سوا رايد، أنت لا تحصل على رحلة فقط؛ بل تنضم إلى حركة تركز على دعم سبل العيش المحلية.",
      },
    ],
    reasonsToChoose: [
      {
        title: "بدون عمولة",
        description:
          "على عكس منصات خدمات النقل الأخرى، نحن لا نأخذ جزءًا من أرباح السائقين، مما يسمح لهم بزيادة دخلهم.",
      },
      {
        title: "مدفوعات مباشرة",
        description:
          "يبسط نظام الدفع P2P لدينا عملية المعاملات، مما يسهل على الركاب الدفع مباشرة للسائقين.",
      },
      {
        title: "لا تسعير مرتفع",
        description:
          "نؤمن بالتسعير العادل، ولهذا السبب نحن لا نشارك في تسعير مرتفع. ما تراه هو ما تدفعه، في كل مرة.",
      },
    ],
  },
};

const AboutUs = ({ language }) => {
  // Select data based on the language
  const { values, reasonsToChoose } = aboutUsData[language] || aboutUsData.en;

  return (
    <div className="bg-black text-white min-h-screen p-6">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-5xl font-semibold text-center my-12 tracking-tight">
          {language === "ar" ? "من نحن" : "About Us"}
        </h1>

        <section className="my-16">
          <h2 className="text-3xl font-medium text-left mb-8">
            {language === "ar" ? "قيمنا" : "Our Values"}
          </h2>
          <ul className="space-y-4 text-left text-lg">
            {values.map((value, index) => (
              <li
                key={index}
                className="flex flex-col items-left p-4 rounded-md transition duration-200 hover:text-gray-400"
              >
                <strong className="text-2xl font-semibold mb-2">
                  {value.title}
                </strong>
                <p className="max-w-3xl text-base text-gray-300">
                  {value.description}
                </p>
              </li>
            ))}
          </ul>
        </section>

        <section className="my-16">
          <h2 className="text-3xl font-medium text-left mb-8">
            {language === "ar"
              ? "لماذا تختار سوا رايد؟"
              : "Why Choose SAWA RIDE?"}
          </h2>
          <ul className="space-y-4 text-left text-lg">
            {reasonsToChoose.map((reason, index) => (
              <li
                key={index}
                className="flex flex-col items-left p-4 rounded-md transition duration-200 hover:text-gray-400"
              >
                <strong className="text-2xl font-semibold mb-2">
                  {reason.title}
                </strong>
                <p className="max-w-3xl text-base text-gray-300">
                  {reason.description}
                </p>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <TeamSection language={language} />
    </div>
  );
};

export default AboutUs;
