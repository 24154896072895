import React, { useState } from "react";

const faqs = {
  en: [
    {
      question: "What is SAWA RIDE?",
      answer:
        "SAWA RIDE is a ride-hailing service designed to connect riders with drivers across Saudi Arabia, focusing on affordability, transparency, and empowering our drivers.",
    },
    {
      question: "How is SAWA RIDE helpful for customers?",
      answer: (
        <ul>
          <li>
            <strong>Affordable Rides:</strong> Our pricing is structured to be
            more economical than other ride-hailing apps, making transportation
            accessible for everyone.
          </li>
          <li>
            <strong>Direct Payment System:</strong> Customers can pay drivers
            directly through various P2P wallets or cash, ensuring a
            straightforward transaction process.
          </li>
          <li>
            <strong>Transparent Pricing:</strong> With no hidden fees and a
            commitment to fair pricing, customers know exactly what they will
            pay for their ride.
          </li>
        </ul>
      ),
    },
    {
      question: "How does the payment process work for rides?",
      answer:
        "Riders can pay drivers directly using various P2P wallets such as STCPay, UrPay, Barq, MobilyPay, or in cash. This direct payment model ensures drivers receive their full earnings without any deductions.",
    },
    {
      question: "What is the monetization strategy for SAWA RIDE?",
      answer:
        "SAWA RIDE aims to disrupt the traditional ride-hailing model by significantly reducing costs in key areas such as maps, cloud services, operations, and marketing. This innovative approach allows us to shift from a high-commission model to a nominal subscription fee for drivers, akin to a mobile recharge. Currently, the platform is free, but we will implement the subscription fee within a couple of months.",
    },
    {
      question: "Is there a commission fee for drivers?",
      answer:
        "No, SAWA RIDE operates on a zero-commission model, meaning drivers keep 100% of their earnings from each ride. We believe in fair compensation for hard work.",
    },
    {
      question: "How does SAWA RIDE's pricing compare to other apps?",
      answer:
        "SAWA RIDE adheres to the pricing guidelines set by the Transport General Authority (TGA). Our current pricing structure is more affordable than that of other ride-hailing apps. We are actively working to establish a fair pricing model that balances customer affordability with driver compensation.",
    },
    {
      question: "Who can sign up to become a driver?",
      answer:
        "Only Saudi nationals can sign up to become drivers with SAWA RIDE. Interested applicants can visit our Driver Sign-Up page for more information.",
    },
    {
      question: "Where is SAWA RIDE currently available?",
      answer:
        "SAWA RIDE is set to launch soon in Riyadh with plans to expand to other regions across the Kingdom.",
    },
    {
      question: "Are there any hidden fees for riders?",
      answer:
        "No, at SAWA RIDE, we believe in transparent pricing. There are no hidden fees; the fare you see is what you pay.",
    },
    {
      question: "What should I do if I experience issues with a ride?",
      answer:
        "If you encounter any issues, please contact our customer support team through the Contact Us section on our website or through the app. We’re available 24/7 to assist you.",
    },
    {
      question: "How do I report a lost item?",
      answer:
        "If you leave an item in a vehicle, contact our customer support team immediately. Provide details about your ride, and we will assist you in recovering your belongings.",
    },
    {
      question: "Is my payment information secure with SAWA RIDE?",
      answer:
        "Absolutely! We prioritize your security and use industry-standard encryption methods to protect your payment information.",
    },
    {
      question:
        "How can I provide feedback about my experience with SAWA RIDE?",
      answer:
        "We value your feedback! You can submit your comments and suggestions through the app after your ride or contact our customer support team directly.",
    },
    {
      question: "What should I do if my driver doesn’t arrive?",
      answer:
        "If your driver does not arrive within the expected time, please check the app for updates. If the issue persists, you can cancel the ride and contact our support for assistance.",
    },
  ],
  ar: [
    {
      question: "ما هو SAWA RIDE؟",
      answer:
        "SAWA RIDE هو خدمة لحجز الرحلات مصممة لربط الركاب بالسائقين في جميع أنحاء المملكة العربية السعودية، مع التركيز على التكلفة المعقولة، والشفافية، وتمكين السائقين.",
    },
    {
      question: "كيف يساعد SAWA RIDE العملاء؟",
      answer: (
        <ul>
          <li>
            <strong>ركوب بأسعار معقولة:</strong> تم هيكلة أسعارنا لتكون اقتصادية
            أكثر من تطبيقات حجز الرحلات الأخرى، مما يجعل التنقل في متناول
            الجميع.
          </li>
          <li>
            <strong>نظام دفع مباشر:</strong> يمكن للعملاء دفع السائقين مباشرة من
            خلال عدة محافظ P2P أو نقداً، مما يضمن عملية دفع سهلة.
          </li>
          <li>
            <strong>أسعار شفافة:</strong> بدون رسوم مخفية والتزام بالأسعار
            العادلة، يعرف العملاء ما سيدفعونه تماماً.
          </li>
        </ul>
      ),
    },
    {
      question: "كيف يعمل نظام الدفع للرحلات؟",
      answer:
        "يمكن للركاب دفع السائقين مباشرة باستخدام محافظ P2P مختلفة مثل STCPay، UrPay، Barq، MobilyPay، أو نقداً. يضمن هذا النموذج من الدفع المباشر أن يحصل السائقون على كامل أرباحهم بدون أي خصومات.",
    },
    {
      question: "ما هي استراتيجية تحقيق الدخل الخاصة بـ SAWA RIDE؟",
      answer:
        "يهدف SAWA RIDE إلى تغيير نموذج حجز الرحلات التقليدي من خلال خفض التكاليف بشكل كبير في المجالات الرئيسية مثل الخرائط والخدمات السحابية والعمليات والتسويق. يسمح هذا النهج المبتكر بالتحول من نموذج العمولات العالية إلى رسوم اشتراك رمزية للسائقين، مثل تعبئة رصيد الجوال. حالياً، المنصة مجانية، ولكن سيتم تطبيق رسوم الاشتراك في غضون بضعة أشهر.",
    },
    {
      question: "هل توجد رسوم عمولة للسائقين؟",
      answer:
        "لا، يعمل SAWA RIDE بنموذج بدون عمولة، مما يعني أن السائقين يحتفظون بـ 100٪ من أرباحهم من كل رحلة. نحن نؤمن بالتعويض العادل عن العمل الجاد.",
    },
    {
      question: "كيف تقارن أسعار SAWA RIDE بتطبيقات أخرى؟",
      answer:
        "SAWA RIDE يتبع إرشادات التسعير التي وضعتها الهيئة العامة للنقل (TGA). هيكل التسعير الحالي لدينا أكثر اقتصاداً من تطبيقات حجز الرحلات الأخرى. نحن نعمل بنشاط على وضع نموذج تسعير عادل يوازن بين القدرة على تحمل تكاليف العملاء وتعويض السائقين.",
    },
    {
      question: "من يمكنه التسجيل ليصبح سائقاً؟",
      answer:
        "يمكن فقط للمواطنين السعوديين التسجيل ليصبحوا سائقين مع SAWA RIDE. يمكن للمتقدمين المهتمين زيارة صفحة التسجيل للحصول على مزيد من المعلومات.",
    },
    {
      question: "أين يتوفر SAWA RIDE حالياً؟",
      answer:
        "SAWA RIDE متاح حالياً في الرياض ويخطط لتوسيع خدماته إلى مناطق أخرى في المملكة قريباً.",
    },
    {
      question: "هل هناك أي رسوم مخفية للركاب؟",
      answer:
        "لا، في SAWA RIDE نؤمن بالشفافية في التسعير. لا توجد رسوم مخفية؛ الأجرة التي تراها هي ما ستدفعه.",
    },
    {
      question: "ماذا أفعل إذا واجهت مشكلة أثناء الرحلة؟",
      answer:
        "إذا واجهت أي مشكلة، يرجى الاتصال بفريق الدعم لدينا عبر قسم اتصل بنا على موقعنا أو من خلال التطبيق. نحن متواجدون 24/7 لمساعدتك.",
    },
    {
      question: "كيف يمكنني الإبلاغ عن شيء مفقود؟",
      answer:
        "إذا تركت شيئاً في السيارة، اتصل بفريق الدعم لدينا فوراً. قدّم تفاصيل حول رحلتك، وسنساعدك في استعادة أغراضك.",
    },
    {
      question: "هل معلومات الدفع الخاصة بي آمنة مع SAWA RIDE؟",
      answer:
        "بالتأكيد! نحن نولي أمانك أولوية ونستخدم تقنيات التشفير القياسية لحماية معلومات الدفع الخاصة بك.",
    },
    {
      question: "كيف يمكنني تقديم ملاحظات حول تجربتي مع SAWA RIDE؟",
      answer:
        "نحن نقدر ملاحظاتك! يمكنك إرسال تعليقاتك واقتراحاتك عبر التطبيق بعد رحلتك أو الاتصال بفريق الدعم مباشرة.",
    },
    {
      question: "ماذا أفعل إذا لم يصل السائق؟",
      answer:
        "إذا لم يصل سائقك في الوقت المتوقع، يرجى التحقق من التطبيق لمعرفة التحديثات. إذا استمرت المشكلة، يمكنك إلغاء الرحلة والتواصل مع الدعم للحصول على المساعدة.",
    },
  ],
};
const FAQ = ({ language }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const currentFAQs = faqs[language]; // Access FAQs based on the selected language

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-black text-white py-16 md:py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto">
          <div className="space-y-6">
            {currentFAQs.map((faq, index) => (
              <div
                key={index}
                className="bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg overflow-hidden"
              >
                <button
                  className="w-full py-4 px-6 text-left focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                  aria-expanded={openIndex === index} // Accessibility
                >
                  <div className="flex items-center justify-between">
                    <span className="text-lg font-semibold">
                      {faq.question}
                    </span>
                    <svg
                      className={`w-6 h-6 transform transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </button>
                {openIndex === index && (
                  <div className="px-6 pb-4">
                    <div className="text-gray-300">{faq.answer}</div>{" "}
                    {/* Wrap the answer in a div */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
